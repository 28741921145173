import { api } from '../api';

const getCities = async () => {
  try {
    const response = await api.get('/shared?city=list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getGeolocation = async () => {
  try {
    const response = await api.get('/geoLocation');
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getLocations = async () => {
  try {
    const response = await api.get('/shared?locations=list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const searchReservation = async params => {
  try {
    const response = await api.get('/reservation', {
      params,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const reservation_init = async payload => {
  try {
    const response = await api.post('/reservation/init', payload);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const post_chekout = async payload => {
  try {
    const response = await api.post('/reservation/checkout', payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const get_chekout = async () => {
  try {
    const response = await api.get('/reservation/checkout');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const post_reservation = async payload => {
  try {
    const response = await api.post('/reservation', payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const post_reservation_admin = async payload => {
  try {
    const response = await api.post('/admin/reservation', payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const update_reservation_admin = async (id, fields) => {
  try {
    const response = await api.put(`admin/reservation/update/${id}`, fields);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const update_reservation_client = async (id, reservation) => {
  try {
    const response = await api.put(`reservation/update/${id}`, reservation);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const get_reservations_admin = async params => {
  try {
    const response = await api.get('admin/reservation/list', {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const get_reservation_admin = async id => {
  try {
    const response = await api.get(`admin/reservation/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  searchReservation,
  getCities,
  getLocations,
  reservation_init,
  get_chekout,
  post_chekout,
  post_reservation,
  get_reservations_admin,
  post_reservation_admin,
  getGeolocation,
  get_reservation_admin,
  update_reservation_client,
  update_reservation_admin,
};
