import { api } from '../api';

const get_reservations_analytics = async params => {
  try {
    const response = await api.get('admin/analytics/reservations', { params });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const get_stations_analytics = async params => {
  try {
    const response = await api.get('admin/analytics/stations', { params });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export { get_reservations_analytics, get_stations_analytics };
