import { getProfile } from '@/services/models/userDatas';
import store from '@/store'; // Importe a store do Vuex

export default async function (to, from, next) {
  const requiresAuth = to.matched.some(
    record => record.meta.requiresAuth || record.meta.dashboardRequiresAuth
  );

  if (!requiresAuth) {
    return next();
  }

  let profile = null;

  try {
    const response = await getProfile();
    if (response) {
      profile = response;
    } else {
      return next({ name: 'Login' });
    }
  } catch (error) {
    console.error('Erro ao verificar o perfil de usuário:', error);
    return next({ name: 'Login' });
  }

  if (to.matched.some(record => record.meta.dashboardRequiresAuth)) {
    const hasAdminAccess =
      profile?.permissions?.is_car_rental_admin ||
      profile?.permissions?.is_super_admin;

    if (!hasAdminAccess) {
      console.warn('Acesso negado: Permissões insuficientes.');
      return next({ name: 'Home' });
    }

    // const hasDashboardAccess = await store.dispatch(
    //   'navigation/setSessionCompany',
    //   profile?.permissions?.car_rental_id
    // );
  }

  if (to.name === 'Dashboard' && to.path === '/dashboard') {
    return next({ name: 'DashboardMain' });
  }

  next();
}
