import axios from 'axios';
import router from '@/router/routes';

const hostname = window.location.hostname;
let url = '';

if (hostname === 'localhost') {
  url = 'http://localhost:3023';
} else if (hostname === 'dev.cartya.com.br') {
  url = 'https://api-dev.cartya.com.br';
} else {
  url = 'https://api.cartya.com.br';
}

// url = "https://api.cartya.com.br";

const api = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      if (router.currentRoute.name !== 'Login') {
        router.push({ path: '/login' });
      }
    }
    return Promise.reject(error);
  }
);

export { api, url };
