import { get_reservations_analytics } from '@/services/models/analytics';

const analytics = {
  namespaced: true,
  state: {
    reservationAnalytics: null,
    stationAnalytics: null,
    selectedInterval: 'Mensal',
  },

  getters: {
    reservationAnalytics: state => state.reservationAnalytics,
    selectedInterval: state => state.selectedInterval,
    stationAnalytics: state => state.stationAnalytics,
  },

  mutations: {
    SET_SELECTED_INTERVAL(state, interval) {
      state.selectedInterval = interval;
    },
    SET_ANALYTICS_RESERVATION(state, payload) {
      state.reservationAnalytics = { ...payload };
    },
    SET_ANALYTICS_STATIONS(state, payload) {
      state.stationAnalytics = { ...payload };
    },
  },

  actions: {
    setSelectedInterval({ commit }, interval) {
      commit('SET_SELECTED_INTERVAL', interval);
    },
    async getReservationsAnalytics({ commit }, data) {
      const response = await get_reservations_analytics(data);
      commit('SET_ANALYTICS_RESERVATION', response.data);
    },
    async getStationsAnalytics({ commit }, data) {
      const response = await get_stations_analytics(data);
      commit('SET_ANALYTICS_STATIONS', response.data);
    },
  },
};

export default analytics;
